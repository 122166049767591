import React, { Component } from 'react';
import { MainContext } from './helpers/Context';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { TransitionGroup, Transition } from "react-transition-group";
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

import LandingPage from './pages/LandingPage/LandingPage';
import About from './pages/About/About';
import Portfolio from './pages/Portfolio/Portfolio';
import Contact from './pages/Contact/Contact';

import BRIcon from './img/br.svg';
import USIcon from './img/us.svg';

class App extends Component {

  constructor() {
    super();
    
    this.currentPage = window.location.pathname;

    this.pages = [
      { url: "/", name_pt: "Home", name_en: "Home", component: LandingPage },
      { url: "/about", name_pt: "Sobre", name_en: "About", component: About },
      { url: "/portfolio", name_pt: "Portfolio", name_en: "Portfolio", component: Portfolio },
      { url: "/contact", name_pt: "Contato", name_en: "Contact", component: Contact }
    ];

    this.languages = ['pt', 'en'];

    let language = this.initialLanguage();

    this.state = {
      language: language ? language : 'pt',
      transitioning: false,
      menuOpened: false
    };
  }

  changeLanguage(language) {
    window.localStorage.setItem("language", language);
    this.setState({ language });
    this.toggleMenu();
  }

  initialLanguage() {
    let language = window.localStorage.getItem("language");

    if (language && this.languages.includes(language)) {
      return language;
    };

    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang ? userLang.substring(0,2) : null;

    if (this.languages.includes(userLang)) {
      return userLang;
    };
  }


  toggleMenu() {
    document.querySelector("#root").scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.setState((state) => {
      return {
        menuOpened: !state.menuOpened
      }
    });
  }

  play(pathname, node, appears) {

    let nextIndex = this.pages.findIndex((item, index) => item.url === pathname);
    let currentIndex = this.pages.findIndex((item, index) => item.url === this.currentPage);
    let samePage = nextIndex === currentIndex;

    this.setState({ transitioning: true });

    const nextNode = node.nextSibling;

    if (currentIndex > nextIndex) {
      node.classList.add("animation-absolute-prev");
    } else if (samePage) {
      node.classList.add("animation-absolute-current");
    } else {
      node.classList.add("animation-absolute-next");
    };

    if (!samePage) {
      node.classList.add("animation-transition");
    };

    setTimeout(() => {
      if (samePage) return;

      node.classList.add("animation-left-0");

      if (nextNode && currentIndex > nextIndex) {
        nextNode.style.left = "100%"
      } else if (nextNode) {
        nextNode.style.left = "-100%";
      };

      this.currentPage = pathname;
    }, 0)

    setTimeout(() => {
      this.setState({ transitioning: false });
    }, samePage ? 0 : 700);

    setTimeout(() => {
      node.classList.remove("animation-absolute-prev");
      node.classList.remove("animation-absolute-next");
      node.classList.remove("animation-absolute-current");
      node.classList.remove("animation-transition");
      node.classList.remove("animation-left-0");
    }, 700);
  }

  render() {
    return (
      <MainContext.Provider value={this.state}>
        <Router>

          <nav className="index-header-options">
            <Link to="/" className="index-header-title">rarokko</Link>
            <div className={`index-header-options-right${this.state.menuOpened ? " index-header-options-menu-opened" : ""}`}>
              <img onClick={() => this.changeLanguage("pt")} className="index-header-options-flags" src={BRIcon} />
              <img onClick={() => this.changeLanguage("en")} className="index-header-options-flags" src={USIcon} />
              
              {this.pages.map((item, index) => {
                return (
                  <Link key={`link_${index}`} onClick={() => this.toggleMenu()} to={item.url} className="index-header-options-item">{item[`name_${this.state.language}`]}</Link>
                )
              })}
            </div>
            <FontAwesomeIcon onClick={() => this.toggleMenu()} icon={this.state.menuOpened ? faTimes : faBars} className="index-header-close"/>
          </nav>
          
          <Route render={({ location }) => {

            const { pathname, key } = location;

            return (
              <TransitionGroup component={null}>
                <Transition
                  key={key}
                  appear={false}
                  enter={true}
                  onEnter={(node, appears) => this.play(pathname, node, appears)}
                  timeout={{ enter: 0, exit: 500 }}
                >
                  <Switch location={location}>
                    {this.pages.map((item, index) => {
                      return ( <Route key={`route_${index}`} path={item.url} exact component={item.component} /> )
                    })}
                    <Route path="*" component={() => <Redirect to="/"/>} />
                  </Switch>
                </Transition>
              </TransitionGroup>
            )
          }} />
        </Router>
        

        {this.state.transitioning &&
          <div ref={this.divBlocker} className="div-blocker"></div>
        }
      </MainContext.Provider>
    );
  }
}

export default App;