import React, { Component } from 'react';
import { MainContext } from '../../helpers/Context';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Buttonkko from '../../components/Buttonkko/Butonkko';
import './LandingPage.css';

import Gab from '../../img/gab.png';

export default class LandingPage extends Component {

    static contextType = MainContext;

    constructor(props, context) {
        super();

        this.h1 = {
            pt: "Olá, sou Gabriel",
            en: "Hello, I'm Gabriel"
        };
        
        this.h2 = {
            pt: "Construo sites e aplicações para desktop e dispositivos móveis.",
            en: "I build websites and apps for desktop and mobile devices."
        };

        this.button = {
            pt: "Conheça meus projetos",
            en: "See my projects"
        };

        this.span = {
            pt: "também conhecido como rarokko",
            en: "also known as rarokko"
        }
    };

    render() {

        let language = this.context.language;

        return(
            <section className="landing-page__main common__main-section">
                <div>
                    <h1 className="landing-page__h1">{this.h1[language]} <span className="landing-page__span">{this.span[language]}</span></h1>
                    <h2 className="landing-page__h2">{this.h2[language]}</h2>

                    <Link to="/portfolio">
                        <Buttonkko 
                            label={this.button[language]}
                            style={{ marginTop: 20 }}
                        />
                    </Link>
                </div>

                <div className="landing-page__picture-div">
                    <img src={Gab}/>
                </div>
                
            </section>
        )
    }

}