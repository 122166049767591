import React, { Component } from 'react';
import { MainContext } from '../../helpers/Context';
import './Contact.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

class Contact extends Component {

  static contextType = MainContext;

  constructor() {
    super();

    this.h1 = {
      pt: "Entre em contato",
      en: "Contact me"
    };

    this.p = {
      pt: "Sinta-se à vontade para falar comigo em qualquer uma das redes sociais acima ou e-mail. Prometo responder o mais rápido possível.",
      en: "Feel free to contact me in any of those social networks or email. I promise that I will try to response as soon as possible."
    };

    this.state = {
      text: this.portuguese
    };
  }

  render() {

    return (
      
      <section className="common__main-section contact__main">
        <h1 className="contact__h1">{this.h1[this.context.language]}</h1>
        <div className="contact-flex-tiles-wrapper">
          <div className="contact-flex-tiles-info">
            <a className="contact-a-email" href="mailto:rarokko@yahoo.com">
              <FontAwesomeIcon icon={faEnvelope} className="contact-fontawesome-icon" />
              rarokko@yahoo.com
            </a>
          </div>
          <div className="contact-flex-tiles-info">
            <a href="http://www.twitter.com/rarokko_" target="_blank">
              <FontAwesomeIcon icon={faTwitter} className="contact-fontawesome-icon-bigger" />
            </a>
            <a href="http://www.instagram.com/rarokko" target="_blank">
              <FontAwesomeIcon icon={faInstagram} className="contact-fontawesome-icon-bigger" />
            </a>
            <a href="https://www.linkedin.com/in/gabriel-henrique-8a5a39145/" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} className="contact-fontawesome-icon-bigger" />
            </a>
            <a href="https://www.github.com/rarokko" target="_blank">
              <FontAwesomeIcon icon={faGithub} className="contact-fontawesome-icon-bigger" />
            </a>
          </div>
          <p className="contact-flex-getintouch">{this.p[this.context.language]}</p>
        </div>
      </section>
    );
  }
}

export default Contact;
