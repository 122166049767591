import React, { Component } from 'react';
import { MainContext } from '../../helpers/Context';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import './About.css';

import GabPc from '../../img/gab-pc.jpg';

export default class About extends Component {

    static contextType = MainContext;

    constructor(props, context) {
        super();

        this.h1 = {
            pt: "Bem vindo ao meu espaço",
            en: "Welcome to my space"
        };

        this.p_1 = {
            pt: `Meu nome é Gabriel Henrique, tenho ${this.calculateAge()} anos e sou desenvolvedor de sistemas. Comecei a desenvolver com 10 anos quando começou a era dos blogs, ganhei uma revista que ensinava HTML básico e ali começou a minha curiosidade por desenvolvimento.`,
            en: `My name is Gabriel Henrique, I am ${this.calculateAge()} years old and I am a systems developer. I started developing at the age of 10 when the blogging era started, I got a magazine that taught basic HTML and there my curiosity for development started.`
        };

        this.p_2 = {
            pt: `Me especializei em desenvolvimento front-end e com 16 anos tive minha primeira experiencia profissional como freelancer. Atualmente trabalho como desenvolvedor frontend senior na Pipefy.`,
            en: "I specialized in front-end development and at 16 years old I had my first professional experience as a freelancer. I currently work as a senior frontend developer at Pipefy."
        };
        
        this.p_3 = {
            pt: `Tenho como hobby jogar video game e assistir animes. Em algumas ocasiões raras toco bateria. Também gosto bastante de estudar nos tempos livres.`,
            en: "My hobby is basically video games and watching animes. On rare occasions I play drums. I also really enjoy studying in my free time."
        };

        this.p_4 = {
            pt: `Meus projetos pessoais costumam surgir de necessidades que encontro para o meu dia a dia. Gosto de aproveitar esses projetos para por em prática tecnologias novas que ainda não tive oportunidade de utilizar.`,
            en: "My personal projects usually arise from needs that I find for my day to day. I like to take advantage of these projects to put into practice new technologies that I have not yet had the opportunity to use."
        };     

        this.p_development = {
            pt: `Desenvolvimento`,
            en: "Development"
        };

        this.p_design = {
            pt: `Design`,
            en: "Design"
        };

        this.p_project = {
            pt: `Projeto`,
            en: "Project"
        };



        this.development = ['HTML', 'JavaScript', 'TypeScript', 'JQuery', 'CSS', 'SASS', 'React', 'React Native', 'Angular', 'Vue', 'Flutter', 'GraphQL', 'MySQL', 'MongoDB', 'Node.js', 'COBOL', 'PHP', 'Webpack', 'GIT', 'AWS', 'Vercel', 'Styled Components']
        this.design = ['XD', 'Invision', 'Photoshop'];
        this.project = ['PPM', 'Trello', 'Jira', 'Pipefy'];
    };

    calculateAge() {
        let myBirthday = new Date(1992, 10, 28)
        let diff_ms = Date.now() - myBirthday.getTime();
        let age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    render() {

        let language = this.context.language;

        return(
            <section className="about__main common__main-section">

                <div className="about__side">                
                    <div className="about__picture-div">
                        <img src={GabPc}/>
                    </div>

                    <p className="about__side-title">{this.p_development[language]}</p>
                    <div className="about__skills-list">
                        {this.development.map((item, index) => {
                            return ( <span key={`development_${index}`}>{item}</span> )
                        })}
                    </div>

                    <p className="about__side-title">{this.p_design[language]}</p>
                    <div className="about__skills-list">
                        {this.design.map((item, index) => {
                            return ( <span key={`design_${index}`}>{item}</span> )
                        })}
                    </div>

                    <p className="about__side-title">{this.p_project[language]}</p>
                    <div className="about__skills-list">
                        {this.project.map((item, index) => {
                            return ( <span key={`project_${index}`}>{item}</span> )
                        })}
                    </div>
                </div>


                <div className="about__text">
                    <h1 className="about__h1">{this.h1[language]}</h1>
                    <p className="about__p">{this.p_1[language]}</p>
                    <p className="about__p">{this.p_2[language]}</p>
                    <p className="about__p">{this.p_3[language]}</p>
                    <p className="about__p">{this.p_4[language]}</p>
                </div>
                
            </section>
        )
    }

}
