import React, { Component } from 'react';
import { MainContext } from '../../helpers/Context';
import Sliderko from 'sliderko'
import './Portfolio.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';

import athetop_coverimage from '../../img/portfolio-preview/athetop/coverimage.png';
import athetop_1 from '../../img/portfolio-preview/athetop/1.png';
import athetop_2 from '../../img/portfolio-preview/athetop/2.png';

import blogAthetopPreview from '../../img/portfolio-preview/blog-athetop/coverimage.png';

import beatlog_old_coverimage from '../../img/portfolio-preview/beatlog_old/coverimage.png';
import beatlog_old_1 from '../../img/portfolio-preview/beatlog_old/1.PNG';
import beatlog_old_2 from '../../img/portfolio-preview/beatlog_old/2.PNG';

import beatmanga_coverimage from '../../img/portfolio-preview/beatmanga/coverimage.png';
import beatmanga_1 from '../../img/portfolio-preview/beatmanga/1.PNG';
import beatmanga_2 from '../../img/portfolio-preview/beatmanga/2.PNG';

import beatlog_coverimage from '../../img/portfolio-preview/beatlog/coverimage.png';
import beatlog_1 from '../../img/portfolio-preview/beatlog/1.png';
import beatlog_2 from '../../img/portfolio-preview/beatlog/2.png';
import beatlog_3 from '../../img/portfolio-preview/beatlog/3.png';
import beatlog_4 from '../../img/portfolio-preview/beatlog/4.png';

class Portfolio extends Component {

  static contextType = MainContext;

  constructor() {
    super();

    this.projectList = [
      {
        name: "Beatlog",
        description_pt: "Rede social de gamers para gamers. Mantenha e organize o progresso de jogos que você está jogando, já terminou ou pretende jogar. Interaja com jogadores com interesses semelhantes",
        description_en: "Social network from gamers for gamers. Keep and organize your progress for games that you are playing, alredy finished or are planning to play.",
        url: null,
        coverImage: beatlog_coverimage,
        images: [beatlog_coverimage, beatlog_1, beatlog_2, beatlog_3, beatlog_4],
        platforms: [faDesktop, faAndroid, faApple]
      },
      {
        name: "Beatmanga",
        description_pt: "Criado para uso pessoal, leitura online e offline de mangás não licenciados de forma nativa em dispositivos móveis. Comecei esse projeto pois os apps que tinha na AppStore não me agradavam, tanto por questão de funcionalidade quanto por usabilidade e interface. Aproveitei o projeto para estudar algumas tecnologias novas e o resultado ficou muito legal. Descontinuei o projeto pelo fato da Shueisha ter disponibilizado um leitor de mangá de excelente qualidade e de forma legal.",
        description_en: "Created for personal use. Online and offline reading of non-licensed mangas with a native experience on mobile devices.",
        url: null,
        coverImage: beatmanga_coverimage,
        images: [beatmanga_1, beatmanga_2],
        platforms: [faAndroid, faApple]
      },
      {
        name: "Beatlog (old)",
        description_pt: "Rede social de gamers para gamers. Mantenha e organize o progresso de jogos que você está jogando, já terminou ou pretende jogar. Interaja com jogadores com interesses semelhantes",
        description_en: "Social network from gamers for gamers. Keep and organize your progress for games that you are playing, alredy finished or are planning to play.",
        url: null,
        coverImage: beatlog_old_coverimage,
        images: [beatlog_old_coverimage, beatlog_old_1, beatlog_old_2],
        platforms: [faDesktop, faAndroid, faApple]
      },
      {
        name: "AtheTop",
        description_pt: "Uma plataforma líder em trocas de alimentos, energia e outros materiais, suportado pela tecnologia digital.",
        description_en: "A leading global trading platform for food, energy and metal commodities, empowered by digital technology.",
        url: null,
        coverImage: athetop_coverimage,
        images: [athetop_coverimage, athetop_1, athetop_2],
        platforms: [faDesktop, faAndroid, faApple]
      },
      {
        name: "Blog AtheTop",
        description_pt: "Acompanhe novidades do mercado financeiro pelo mundo todo.",
        description_en: "Follow latest news from the financial market all around the world.",
        url: null,
        coverImage: blogAthetopPreview,
        images: [blogAthetopPreview],
        platforms: [faDesktop, faAndroid, faApple]
      }
    ];

    this.h1 = {
      pt: "Conheça meus projetos",
      en: "See my projects"
    };

    this.h2 = {
      pt: "Você também pode clicar para mais detalhes",
      en: "You can also click for more details"
    };

    this.button = {
      pt: "Fechar",
      en: "Close"
    };

    this.span = {
      pt: "(arraste as imagens para o lado)",
      en: "(swipe to the right)"
    };

    this.platforms = {
      pt: "Plataformas",
      en: "Platforms"
    }

    this.description = {
      pt: "Descrição",
      en: "Description"
    }

    this.detailsSection = React.createRef();

    this.state = {
      active: null,
      pageLoaded: false,
      menuOpened: false
    };
  }

  changeActive(i, ev) {
    let calc = Math.abs(Math.abs(this.mouseDownX) - Math.abs(ev.clientX));
    
    if (calc > 50) return;

    this.setState({ active: i });

    setTimeout(() => {
      
      document.querySelector("#root").scrollTo({
        top: this.detailsSection.current.offsetTop,
        behavior: 'smooth'
      });
      
    }, 0)
  }

  openImage(image) {
    this.setState({ activeImage: image })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ pageLoaded: true })
    }, 300);

    window.addEventListener("mousedown", (ev) => {
      this.mouseDownX = ev.clientX;
    })
  }

  render() {

    let activeProject = this.projectList[this.state.active];

    return (
      <React.Fragment>
        <section className="common__main-section portfolio__main">
          <h1 className="portfolio__h1">{this.h1[this.context.language]}</h1>
          <h2 className="portfolio__h2">{this.h2[this.context.language]}</h2>
          <span className="portfolio__span">{this.span[this.context.language]}</span>
          <Sliderko style={{ flex: 1 }}>
            {this.projectList.map((item, index) => {
              return (
                <div key={`sliderproject_${index}`} className="portfolio__item-slider" onClick={(ev) => this.changeActive(index, ev)}>
                  <div className="portfolio__item-img" style={{ opacity: this.state.pageLoaded ? 1 : 0, backgroundImage: `url(${item.coverImage})` }}/>
                  <a className="portfolio__item-text">{item.name}</a>
                  {item.platforms.map((platform, iconIndex) => {
                    return (<FontAwesomeIcon key={`slidericon_${iconIndex}`} icon={platform} className="portfolio__item-icon"/>)
                  })}
                </div>
              )
            })}
          </Sliderko>
        </section>

        {activeProject != null &&
          <section ref={this.detailsSection} className="portfolio__project-details">
            <div>
              <h1 className="portfolio__details-h1">{activeProject.name}</h1>
              <p className="portfolio__details-p"><strong>{this.platforms[this.context.language]}</strong>
                {activeProject.platforms.map((platform, index) => {
                  return (<FontAwesomeIcon key={`activeicon_${index}`} icon={platform} className="portfolio__item-icon"/>)
                })}
              </p>
              <p className="portfolio__details-p"><strong>{this.description[this.context.language]}</strong> {activeProject[`description_${this.context.language}`]}</p>
              {activeProject.url &&
                <p className="portfolio__details-p"><strong>URL</strong> {activeProject.url}</p>
              }
            </div>
            <div className="portfolio__details-images">
              {activeProject.images.map((image, index) => {
                return (
                  <div
                    key={`images_${index}`}
                    className="portfolio__details-images-item" style={{ backgroundImage: `url(${image})`}}
                    onClick={() => this.openImage(image)}
                  />
                )
              })}
            </div>
          </section>
        }

        {this.state.activeImage != null &&
          <div className="portfolio__image-fullscreen" onClick={() => this.openImage(null)}>
            <img src={this.state.activeImage}></img>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default Portfolio;
